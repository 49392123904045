import React from "react";
import styled from "styled-components";
//
import Footer from "./footer";
import NavLink from "./navLink";
import HomeLink from "./homeLink";
//
export default ({ contact, mobileActive, onClick, links, ...rest }) => (
  <Wrap mobileActive={mobileActive}>
    <Nav>
      <List>
        <HomeLink />
        {links.map((link) => (
          <NavLink
            key={link.id}
            {...link}
            mobileActive={mobileActive}
            onClick={onClick}
          />
        ))}
      </List>
    </Nav>
    <Footer {...contact} />
  </Wrap>
);
//
const Wrap = styled.div`
  background-color: #ffff;
  display: ${({ mobileActive }) => (mobileActive ? " grid" : "none")};
  grid-gap: 3rem;
  justify-items: center;
  margin: 0 auto;
  padding-top: 3rem;
  padding-top: calc(3rem + 2px);
  width: 100vw;
  min-height: 100vh;

  @media (min-width: 769px) {
    display: grid;
    justify-content: space-between;
    justify-items: start;
    padding: 1rem 0;
    max-width: 205px;
    min-height: calc(100vh - 75px);
  }
`;
const Nav = styled.nav`
  a {
    color: #213e65;
    font-size: 1.2em;

    :hover,
    &.active {
      color: #aba719;
    }
  }
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;
