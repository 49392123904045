import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
// this is only active when the location pathname is exactly
// the same as the href.
const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null;
};

const ExactNavLink = (props) => <Link getProps={isActive} {...props} />;

// this link will be active when itself or deeper routes
// are current
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: "active" } : null;
};

const PartialNavLink = (props) => (
  <Link getProps={isPartiallyActive} {...props} />
);

export default ({ ...rest }) => (
  <Wrap>
    <ExactNavLink to="/" title="Skyk Home">
      Etusivu
    </ExactNavLink>
  </Wrap>
);
//
const Wrap = styled.li`
  border-bottom: 1px solid #e5ca00;
  border-bottom: 1px solid #aba719;
  border-bottom: 1px solid #d9dce4;
  display: inline-block;
  text-transform: capitalize;
  // margin: 0 auto;
  max-width: 300px;

  a {
    display: inline-block;
    padding: 0.5rem;
  }

  :last-child {
    border: none;
  }

  // &:not(:last-child):after {
  //   background-color: currentColor;
  //   content: "";
  //   display: block;
  //   height: 1px;
  //   margin: 0 0.5rem;
  //   width: 1rem;
  // }
`;
