import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
export default ({ ...rest }) => (
  <Wrap>
    <RichText {...rest} />
  </Wrap>
);
//

const Wrap = styled.footer`
  font-size: 0.9em;
  padding: 0.5rem;
  padding-bottom: 3rem;

  p {
    margin: 0.6rem 0;
  }

  h3 {
    margin: 1.5em 0 0;
    :after {
      background-color: #aba719;
      content: "";
      display: block;
      height: 2px;
      width: 1rem;
      margin: 0.5rem 0;
    }
  }

  @media (min-width: 769px) {
    align-self: end;
    padding-bottom: 0.5rem;
  }
`;
